import { XF } from "./XF";

XF.Element.extend("re-captcha", {
  init() {
    if (!this.options.sitekey) {
      return;
    }

    const $form = this.$target.closest("form");
    this.$target.siblings("noscript").remove();

    if (this.options.invisible) {
      const $reCaptchaTarget = $("<div />");
      let $target = this.$target.closest(".formRow");

      if (!$target[0]) {
        $target = this.$target;
      }

      $target.hide();
      $target.after($reCaptchaTarget);
      this.$reCaptchaTarget = $reCaptchaTarget;

      $form.on("ajax-submit:before", XF.proxy(this, "beforeSubmit"));
    } else {
      this.$reCaptchaTarget = this.$target;
    }

    $form.on("ajax-submit:error ajax-submit:always", XF.proxy(this, "reload"));

    if (window.grecaptcha) {
      this.create();
    } else {
      XF.ReCaptcha.Callbacks.push(XF.proxy(this, "create"));

      $.ajax({
        url: "https://www.google.com/recaptcha/api.js?onload=XFReCaptchaCallback&render=explicit",
        dataType: "script",
        cache: true,
        global: false,
      });
    }
  },
  beforeSubmit(e, config) {
    if (!this.invisibleValidated) {
      e.preventDefault();
      // eslint-disable-next-line no-param-reassign
      config = config || {};
      config.preventSubmit = true;

      window.grecaptcha.execute(this.reCaptchaId);
    }
  },
});
